import { GridCell } from '@almbrand/grid';
import classNames from 'classnames';
import { Component } from 'components/Component';

export const GridCellComponent: React.FC<{ component: ContentApiModel; applySpacings?: boolean }> = ({
	component,
	applySpacings,
}) => {
	const { gridSize, tabletGridSize, mobileGridSize, gridMove, mobileGridMove, tabletGridMove } =
		component?.grid ?? {};

	const getGridSize = (size?: GridCellWidth | 'None') => {
		return !size || size === 'None' ? '12col' : size;
	};

	const { marginBottom, marginTop } = component?.style ?? {};

	return (
		<GridCell
			desktopWidth={gridSize}
			tabletWidth={getGridSize(tabletGridSize)}
			mobileWidth={getGridSize(mobileGridSize)}
			desktopOffset={gridMove}
			tabletOffset={mobileGridMove}
			mobileOffset={tabletGridMove}
			className={applySpacings && classNames(`pb-${marginBottom}`, `pt-${marginTop}`)}
		>
			<Component {...component?.content} />
		</GridCell>
	);
};
