import { Youtube, YoutubeProps } from '@almbrand/youtube';
import React from 'react';

interface YoutubeComponentProps {
	thumbnailImage?: {
		url: string;
		type: string;
		properties: {
			contentType: string;
			contentId: number;
			imageFocalPoint?: {
				x: string;
				y: string;
			};
			height: number;
			width: number;
		};
	};
	youtubeId: string;
	contentId?: string | number;
	readlineId?: string | number;
	useReadline?: boolean;
}

export const YoutubeComponent: React.FC<YoutubeComponentProps> = ({
	thumbnailImage,
	youtubeId,
	useReadline,
	contentId,
	readlineId,
}) => {
	// Map CMS content to YoutubeProps
	const youtubeProps: YoutubeProps = {
		id: useReadline && readlineId ? readlineId : contentId,
		youtubeId: youtubeId,
		thumbnailImage: thumbnailImage?.url || '',
	};

	return <Youtube {...youtubeProps} />;
};
